import { Autocomplete, Box, Checkbox, TextField, Typography, useTheme } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import CustomTable from './CustomTable';
import { getAllStudents, getApprovedTeachers, getTeacherData, sendStudentUpdate } from '../../api/content-api';
import SnackbarContext from '../../store/snackbar-context';
import Loader from '../../ui/Loader';
import Teacher from '../../model/Teacher';
import AuthContext from '../../store/auth-context';
import { useNavigate } from 'react-router-dom';
import { loginPage } from '../../store/fixed-routing';
import Student from '../../model/Student';
import ActionButtons from './StagesTables/ActionButtons';
import dayjs from 'dayjs';
import OlympicsContext from '../../store/olympics-context';
import { filter } from './StagesTable';
import EditStudentDialog from "./EditStudentDialog";
import {MangedForm} from "../../ui/Form/MangedForm";
import {useCustomTheme} from "../../store/theme-context";

const StudentsTable = () => {
	const [isLoading, setIsLoading] = useState(true);
	const { palette } = useTheme();
	const { isHighContrast } = useCustomTheme();
	const navigate = useNavigate();
	const { setMsg } = useContext(SnackbarContext);
	const { logout, token, isTeacher, isSecretary, isAdmin, id } = useContext(AuthContext);
	const { olympics, districtFilterId } = useContext(OlympicsContext);
	const [students, setStudents] = useState<Student[]>([]);
	const [displayedStudents, setDisplayedStudents] = useState<Student[]>([]);
	const [teachersChoice, setTeachersChoice] = useState<Teacher[]>([]);
	const [editedStudent, setEditedStudent] = useState<Student | null>();
	const [editedStudentAll, setEditedStudentAll] = useState<Student | null>();
	const [inProgress, setInProgress] = useState(false);
	const editable = isAdmin || olympics?.registrationConfirmationFinishDate?.isAfter(dayjs());

	const handleCloseEditStudent = () => {
		setEditedStudentAll(null);
	}

	const downloadData = () => {
		getAllStudents(token)
			.then((response) => {
				if (response.status === 200) {
					const students = response.data.map((d: any) => Student.fromApiResponse(d));
					setStudents(students);
					setDisplayedStudents(filter(students, districtFilterId));
				} else {
					console.log(response);
					setMsg({ msg: response.data });
				}
				setIsLoading(false);
			})
			.catch((exception) => {
				console.error(exception);
				if (exception.response.status === 401 || exception.response.status === 403) {
					logout();
					navigate(loginPage);
					setMsg({ msg: 'Nie masz uprawnień albo Twoja sesja wygasła' });
				} else {
					setMsg({ msg: 'Błąd serwera. Spróbuj za chwilę' });
				}
				setIsLoading(false);
			});
	};

	const onSuccess = () => {
		downloadData();
		setEditedStudent(null);
	};

	useEffect(() => {
		downloadData();
		if (isTeacher) {
			getTeacherData(token, id)
				.then((response) => {
					if (response.status === 200) {
						const teacher = Teacher.fromApiResponse(response.data);
						setTeachersChoice([teacher]);
					}
				})
				.catch((exception) => {
					console.error(exception);
					setMsg({ msg: 'Błąd serwera. Spróbuj za chwilę' });
				});
		} else if (isSecretary) {
			getApprovedTeachers(token)
				.then((response) => {
					if (response.status === 200) {
						const teachers = response.data.map((d: any) => Teacher.fromApiResponse(d));
						setTeachersChoice(teachers);
					}
				})
				.catch((exception) => {
					console.error(exception);
					setMsg({ msg: 'Błąd serwera. Spróbuj za chwilę' });
				});
		}
	}, []);

	useEffect(() => {
		setDisplayedStudents(filter(students, districtFilterId));
	}, [districtFilterId]);

	const handleApproveChange = () => {
		if (editedStudent) {
			setEditedStudent({
				...editedStudent,
				approved: !editedStudent.approved,
			});
		}
	};

	const handleSave = () => {
		setInProgress(true);
		if (inProgress) {
			return;
		}
		sendStudentUpdate(token, editedStudent)
			.then((response) => {
				if (response.status === 200) {
					onSuccess();
					setMsg({ msg: 'Dane zostały zaktualizowane', severity: 'success' });
				} else {
					setMsg({ msg: response.data });
				}
				setInProgress(false);
			})
			.catch((exception) => {
				console.error(exception);
				if (exception.response.status === 401 || exception.response.status === 403) {
					logout();
					navigate(loginPage);
					setMsg({ msg: 'Nie masz uprawnień albo Twoja sesja wygasła' });
				} else {
					setMsg({ msg: 'Błąd serwera. Spróbuj za chwilę' });
				}
				setInProgress(false);
			});
	};

	const renderApproveField = (s: Student) => {
		const value = editedStudent?.id === s.id ? editedStudent.approved : s.approved;
		return (
			<Checkbox
				checked={value}
				disabled={editedStudent?.id !== s.id || s.approved}
				onChange={handleApproveChange}
			/>
		);
	};

	const handleTeachersUpdate = (teachers: Teacher[]) => {
		if (editedStudent) {
			if (teachers.length > 3) {
				setMsg({ msg: 'Uczeń może być przypisany do maksymalnie 3 nauczycieli' });
				return;
			}
			if (isSecretary) {
				setEditedStudent({ ...editedStudent, teachers: teachers });
			} else if (isTeacher) {
				let dif1 = editedStudent?.teachers.filter((item1) => !teachers.some((item2) => item2.id === item1.id));
				dif1 = dif1 ? dif1 : [];
				const dif2 = teachers.filter((item1) => !editedStudent?.teachers.some((item2) => item2.id === item1.id));
				const dif = [...dif1, ...dif2];
				if (dif.every((item) => item.wpUserId === id)) {
					setEditedStudent({ ...editedStudent, teachers: teachers });
				} else {
					setMsg({ msg: 'Brak uprawnień by zmieniać przypisanie innych nauczycieli' });
				}
			}
		}
	};

	const renderTeachersField = (s: Student) => {
		return s.id === editedStudent?.id ? (
			<Autocomplete
				multiple
				value={editedStudent?.teachers ? editedStudent?.teachers : []}
				onChange={(event: any, newValue: Teacher[]) => handleTeachersUpdate(newValue)}
				options={teachersChoice}
				disableClearable
				forcePopupIcon={false}
				getOptionLabel={(t: Teacher) => `${t.firstName} ${t.lastName}`}
				isOptionEqualToValue={(option: Teacher, value: Teacher) => {
					return option.id === value.id;
				}}
				sx={{
					width: '100%',
					fontSize: '14px',
					'& input': {
						width: '100%',
						fontSize: '14px',
						color: palette.primary.main,
					},
					'& .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon': {
						padding: '9px !important',
						backgroundColor: 'red',
					},
					'& .MuiOutlinedInput-root': {
						width: '100%',
						'& fieldset': {
							border: 'none',
						},
					},
					'& svg': {
						color: isHighContrast ? palette.primary.light + '!important' : 'inherit'
					}
				}}
				renderInput={(params) => (
					<TextField
						{...params}
						placeholder='Wybierz nauczyciela'
						sx={{
							width: '100%',
							'& .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon': {
								padding: '9px !important',
								backgroundColor: 'red',
							},
						}}
					/>
				)}
				renderOption={(props, option, { inputValue }) => {
					return (
						<Box
							component={'li'}
							sx={{
								fontSize: '16px',
								p: '10px',
							}}
							{...props}
						>
							{`${option.firstName} ${option.lastName}`}
						</Box>
					);
				}}
			/>
		) : (
			<Typography
				variant='caption'
				fontWeight='medium'
			>
				{s.teachers.map((t: Teacher) => `${t.firstName} ${t.lastName}, `)}
			</Typography>
		);
	};

	const data = (input: any) => {
		return {
			columns: [
				{ header: 'Potwierdzony', accessor: 'approved', align: 'center', width: '150px', editable: editable },
				{ header: 'Tytuł', accessor: 'personalTitle', align: 'left', width: '100px' },
				{ header: 'Imię', accessor: 'firstName', align: 'left' },
				{ header: 'Drugie imię', accessor: 'secondName', align: 'left' },
				{ header: 'Nazwisko', accessor: 'lastName', align: 'left' },
				{ header: 'Orzeczenie', accessor: 'specialCertificate', align: 'left', hidden: !(isAdmin || isSecretary) },
				{ header: 'Szkoła', accessor: 'school', align: 'left' },
				{ header: 'Klasa', accessor: 'schoolClass', width: '100px', align: 'center' },
				{ header: 'Nauczyciele', accessor: 'teachers', align: 'left', editable: editable },
				{ header: 'Punkty', accessor: 'points', align: 'left', editable: editable },
				{ header: 'Adres', accessor: 'address', align: 'left', hidden: !(isAdmin || isSecretary) },
				{ header: 'Dane kontaktowe', accessor: 'contactDetails', align: 'left', hidden: !(isAdmin || isSecretary) },
				{ header: 'Dane do zaświadczenia', accessor: 'certificateDetails', align: 'left', hidden: !(isAdmin || isSecretary) },
				{ header: 'Data i miejsce urodzenia', accessor: 'birthDetails', align: 'left', hidden: !(isAdmin || isSecretary) },
				{ header: 'Deklaracje', accessor: 'declarations', align: 'left', hidden: !(isAdmin || isSecretary) },
				{ header: '', accessor: 'edit', width: '100px', nosort: true, editable: editable },
			],

			rows: input
				? input.map((i: Student) => {
						return {
							approved: renderApproveField(i),
							personalTitle: (
								<Typography
									variant='caption'
									fontWeight='medium'
								>
									{i.personalTitle}
								</Typography>
							),
							firstName: (
								<Typography
									variant='caption'
									fontWeight='medium'
								>
									{i.firstName}
								</Typography>
							),
							secondName: (
								<Typography
									variant='caption'
									fontWeight='medium'
								>
									{i.secondName}
								</Typography>
							),
							lastName: (
								<Typography
									variant='caption'
									fontWeight='medium'
								>
									{i.lastName}
								</Typography>
							),
							specialCertificate: (isAdmin || isSecretary) && (
								<Typography
									variant='caption'
									fontWeight='medium'
								>
									{i.specialCertificate ? 'Tak' : 'Nie'}
								</Typography>
							),
							school: (
								<Typography
									variant='caption'
									fontWeight='medium'
								>
									{i.school.name}, {i.school.city}
								</Typography>
							),
							schoolClass: (
								<Typography
									variant='caption'
									fontWeight='medium'
								>
									{i.schoolClass}
								</Typography>
							),
							teachers: renderTeachersField(i),
							address: (
								<Typography variant='caption'>
									{`${i.city}, ${i.zipCode}, ${i.street} ${i.building} ${i.apartment !== '' ? '/' + i.apartment : ''}`}
								</Typography>
							),
							contactDetails: (isAdmin || isSecretary) && (
								<Typography variant='caption'>{`email: ${i.email}, telefon: ${i.phone}`}</Typography>
							),
							certificateDetails: (isAdmin || isSecretary) && (
								<Typography
									variant='caption'
									dangerouslySetInnerHTML={{
										__html:	i.certEmail && `email: ${i.certEmail},<br/> adres: ${i.certCity}, ${i.certZipCode}, ${i.certStreet} ${i.certBuilding} ${i.certApartment !== '' ? '/' + i.certApartment : ''}`
								}}
								></Typography>
							),
							birthDetails: (isAdmin || isSecretary) && (
								<Typography variant='caption'>{i.birthdate && `${dayjs(i.birthdate).locale('pl').format('YYYY-MM-DD')} w ${i.birthdatePlace ? i.birthdatePlace : ''}`}</Typography>
							),
							declarations: (isAdmin || isSecretary) && (
								<Typography variant='caption'
									dangerouslySetInnerHTML={{
										__html:	i.declarations.map((d:string) => '- ' + d).join('<br/>')
									}}
								>
								</Typography>
							),
							edit: (
								<ActionButtons
									editedStudent={editedStudent}
									student={i}
									isAdmin={isAdmin}
									disabled={!editable}
									handleClose={() => setEditedStudent(undefined)}
									handleEdit={() => setEditedStudent(i)}
									handleEditStudent={() => setEditedStudentAll(i)}
									handleSave={handleSave}
									inProgress={inProgress}
								/>
							),
						};
				  })
				: [],
		};
	};

	return <>{isLoading ? <Loader /> : <>
		<CustomTable data={data(displayedStudents)} />
		{editedStudentAll && <MangedForm>
			<EditStudentDialog student={editedStudentAll} open={!!editedStudentAll} onClose={handleCloseEditStudent} />
		</MangedForm>}
	</>}</>;
};

export default StudentsTable;
